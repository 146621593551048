import {
  useMutation,
  UseMutationResult,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  authLogin,
  refreshToken,
  sendFCMToken,
} from '@/controllers/auth.controller';
import initFirebaseNotifications from '../utils/firebase';
import useSmartToast from './SmartToast';

const AuthContext = createContext<AuthProps>(null);

export const useAuth = (): AuthProps => useContext(AuthContext);

type LoginStatus = 'LOGGED_IN' | 'LOGGED_OUT' | 'UNKNOWN';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<User>(null);
  const [token, setToken] = useState<string>(null);
  const [loginStatus, setLoginStatus] = useState<LoginStatus>('UNKNOWN');
  const toast = useSmartToast();
  const router = useRouter();

  const refresh = async () => {
    const prcRefreshToken = localStorage.getItem('procura_refresh_token');
    if (!prcRefreshToken) return false;

    try {
      const { status, data } = await refreshToken(token, prcRefreshToken);

      if (status == 200) {
        localStorage.setItem('procura_user', JSON.stringify(data.user));
        setUser(data.user);

        if (data.accessToken) {
          localStorage.setItem('procura_access_token', data.accessToken);
          setToken(data.accessToken);
        }

        if (data.refreshToken) {
          localStorage.setItem('procura_refresh_token', data.refreshToken);
        }
        return true;
      }
    } catch (error) {
      const status = error.response?.status;
      if (status == 401 || status == 403)
        toast.error(
          error.response?.data?.message,
          'Session expired, please login again',
        );
      else if (status == 404)
        toast.error(
          error.response?.data?.message,
          'Logged in user not found, please login again',
        );
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('procura_access_token');
    localStorage.removeItem('procura_refresh_token');
    localStorage.removeItem('procura_user');
    localStorage.removeItem('fcm_token');
    setToken(null);
    setUser(null);
    setLoginStatus('LOGGED_OUT');
  };

  const checkTokenPresent = async () => {
    if (localStorage.getItem('procura_access_token') !== null) {
      setToken(localStorage.getItem('procura_access_token'));
    } else setLoginStatus('LOGGED_OUT');

    if (localStorage.getItem('procura_user') !== null) {
      const userData = JSON.parse(localStorage.getItem('procura_user'));
      setUser({
        ...userData,
        permissions: userData.permissions || [],
        accountType: 'PROCURA',
      });
    } else setLoginStatus('LOGGED_OUT');

    if (localStorage.getItem('procura_refresh_token') !== null) {
      setToken(localStorage.getItem('procura_refresh_token'));
      try {
        const refreshed = await refresh();
        if (refreshed) setLoginStatus('LOGGED_IN');
        else setLoginStatus('LOGGED_OUT');
      } catch (error) {
        if (error.status == 401 || error.status == 403) logout();
        else toast.error(error.message, 'Error verifying your session');
      }
    } else setLoginStatus('LOGGED_OUT');
  };

  const login = useMutation({
    mutationFn: (data: {
      email: string;
      password: string;
      type: 'admin' | 'vendor' | 'client';
    }) => authLogin(data),
    onSuccess: data => {
      localStorage.setItem('procura_access_token', data.accessToken);
      localStorage.setItem('procura_refresh_token', data.refreshToken);
      localStorage.setItem('procura_user', JSON.stringify(data.user));
      setToken(data.token);
      setUser(data.user);

      // Set login status to LOGGED_IN and redirect
      setLoginStatus('LOGGED_IN');
      router.push((router.query.redirect as string) || '/');
    },
    onError: (error: AxiosError<{ message: string }>) => {
      toast.error(error, error.response?.data?.message);
    },
  });

  const firebaseFCMToken = useQuery({
    queryKey: ['firebaseFCMToken', loginStatus],
    queryFn: initFirebaseNotifications,
    retry: false,
    enabled: loginStatus === 'LOGGED_IN',
    refetchOnWindowFocus: false,
  });

  if (firebaseFCMToken.isSuccess && !!firebaseFCMToken.data)
    sendFCMToken(firebaseFCMToken.data || localStorage.getItem('fcm_token'));

  const hasPermission = (permission: UserPermission) =>
    user?.permissions?.includes('superadmin') ||
    user?.permissions?.includes(permission) ||
    false;

  const hasPermissions = (...permissions: UserPermission[]) => {
    return permissions.some(permission => hasPermission(permission));
  };

  useEffect(() => {
    checkTokenPresent();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        token,
        loginStatus,
        hasPermission,
        hasPermissions,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface AuthProps {
  login: UseMutationResult<
    any,
    Error,
    {
      email: string;
      password: string;
      type: 'admin' | 'vendor' | 'client';
    },
    unknown
  >;
  logout: () => void;
  user: User;
  token: string;
  loginStatus: LoginStatus;
  hasPermission: (permission: UserPermission) => boolean;
  hasPermissions: (...permissions: UserPermission[]) => boolean;
  refresh: () => Promise<boolean>;
}

interface User {
  id: string;
  name: string;
  email: string;
  type: string;
  accountType: 'PROCURA' | 'SAAS';
  organization?: {
    id: string;
    name: string;
    requirePodVerification: boolean;
    requireStockTracking: boolean;
  };
  role?: {
    id: string;
    name: string;
    canCreateOrder: boolean;
    canCreateOrderRequest: boolean;
    canViewAllAddresses: boolean;
  };
  permissions: UserPermission[];
  profileCompleted?: boolean;
  enabled?: boolean;
}

const allPermissions = {
  superadmin: {
    group: 'admin',
    label: 'Super Admin',
    description: 'Full Access',
  },
  'clients.create': {
    group: 'clients',
    label: 'Create Clients',
    description: 'Create new clients, accounts and addresses',
  },
  'clients.manage': {
    group: 'clients',
    label: 'Manage Clients',
    description: 'Edit clients, accounts and addresses',
  },
  'clients.delete': {
    group: 'clients',
    label: 'Delete Clients',
    description: 'Delete clients, accounts and addresses',
  },
  'vendors.create': {
    group: 'vendors',
    label: 'Create Vendors',
    description: 'Create new vendors, accounts and addresses',
  },
  'vendors.manage': {
    group: 'vendors',
    label: 'Manage Vendors',
    description: 'Edit vendors, accounts and addresses',
  },
  'vendors.delete': {
    group: 'vendors',
    label: 'Delete Vendors',
    description: 'Delete vendors, accounts and addresses',
  },
  'orders.manage': {
    group: 'orders',
    label: 'Manage Orders',
    description: 'Create, edit and manage orders',
  },
  'orders.cancel': {
    group: 'orders',
    label: 'Cancel Orders',
    description: 'Cancel orders',
  },
  'porders.manage': {
    group: 'porders',
    label: 'Manage Purchase Orders',
    description: 'Create, edit and manage purchase orders',
  },
  'porders.cancel': {
    group: 'porders',
    label: 'Cancel Purchase Orders',
    description: 'Cancel purchase orders',
  },
  'shop.manage': {
    group: 'shop',
    label: 'Manage Shop',
    description: 'Manage shop products, categories and orders',
  },
  'quotations.manage': {
    group: 'quotation',
    label: 'Access Quotation Maker',
    description: 'Create, edit and manage quotations on Quotation Maker',
  },
  'invoice-requests.create': {
    group: 'invoice-requests',
    label: 'Create Invoice Requests',
    description: 'Create new invoice requests',
  },
  'invoice-requests.view': {
    group: 'invoice-requests',
    label: 'View Invoice Requests',
    description: 'View invoice requests',
  },
  'invoice-requests.manage': {
    group: 'invoice-requests',
    label: 'Manage Invoice Requests',
    description: 'Update invoice requests',
  },
  'payment-requests.create': {
    group: 'payment-requests',
    label: 'Create Payment Requests',
    description: 'Create new payment requests',
  },
  'payment-requests.view': {
    group: 'payment-requests',
    label: 'View Payment Requests',
    description: 'View payment requests',
  },
  'payment-requests.manage': {
    group: 'payment-requests',
    label: 'Manage Payment Requests',
    description: 'Update payment requests',
  },
  'links.manage': {
    group: 'links',
    label: 'Manage Links',
    description: 'Create, edit, manage and delete custom links',
  },
  'reports.general': {
    group: 'reports',
    label: 'View Reports',
    description: 'View reports and analytics',
  },
  'reports.sensitive': {
    group: 'reports',
    label: 'View Sensitive Reports',
    description: 'View sensitive reports and analytics',
  },
  'crm.all': {
    group: 'crm',
    label: 'Access CRM',
    description: 'Create and Update Client Leads',
  },
  'vms.all': {
    group: 'vms',
    label: 'Access VMS',
    description: 'Create and Update Vendor Leads',
  },
  'rfq.all': {
    group: 'rfq',
    label: 'Manage RFQ',
    description: 'Create, edit and manage RFQ',
  },
  'rfq.create': {
    group: 'rfq',
    label: 'Create RFQ',
    description: 'Create and edit RFQs',
  },
};

export type UserPermission = keyof typeof allPermissions;
